import {Model} from "../vuemc";

export default class Register extends Model
{
    defaults() {
        return {
            practice: null,
        }
    }
}
